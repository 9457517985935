@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out; /* Adjust the animation duration and timing function as needed */
}


.container {
  justify-items: center;
  justify-content: center;
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: row;
  column-gap: 20px;
  grid-template-columns: 300px 300px 300px;
  grid-template-rows: 350px 350px 350px;
}

.header {
  display: flex;
  right: 400px;
  flex-direction: row;
  justify-content: center;
}
.body-section-row {
  display: flex;
  flex-direction: row;
  padding: 60px;
  font-size: 18px;
  border-top: 0.5px solid silver; /* Add a solid black line at the top */

}

.body-section-column {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.body-section:hover {
  margin-top: -5px;
  margin-bottom: +5px;
  transition-duration: 0.5s;
}

.link {
  text-decoration: none;
  color: black;
}
