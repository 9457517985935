.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.grid-item {
  color: black;
  flex: 1;
  font-size: 16px;
  text-align: center;
  padding: 20px;
  background-color: #E4DBF3;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  margin: 30px;
  width: 100%;
  display: inline-block;
}

.grid-container1 {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.flip-card {
  background-color: transparent;
  width: 275px;
  height: 250px;
  border-radius: 25px;
  margin: 20px;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  background-color: #a887dd;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  box-sizing: border-box;
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
  color: white;
  font-size: 16px;
  text-align: center;
  background-color: #a887dd;
  box-sizing: border-box;
  padding: 30px;
}

.big-black-title {
  color: black;
  text-align: center;
  font-size: 35px;
  /* margin-top: 15px;
  margin-bottom: 15px; */
  font-weight: 500;
}
