.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 1000px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
