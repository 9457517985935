/* .body {
  padding: 20px;
  margin-left: 150px;
  margin-right: 150px;
} */

.header {
  font-size: 30px;
}
.underline {
  background-color: #9641fd;
  height: 10px;
  width: 100%;
}

.grid-containerw {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  font-size: 30px;
  text-align: center;
  justify-self: center;
}
