@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  font-family: "Cooper";
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Cooper";
  src: local("Cooper"),
    url("./assets/Fonts/cooper-hewitt/CooperHewitt-Medium.otf")
      format("opentype");
}
